<template>
  <b-form>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Firma Ünvanı"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="formData.title"
              placeholder="Firma Ünvanı"
            />
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="4"
        >
          <b-form-group
            label="Telefon"
            label-for="phone"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                TR (+90)
              </b-input-group-prepend>
              <cleave
                id="phone"
                v-model="formData.phone"
                class="form-control"
                :raw="true"
                :options="options.phone"
                placeholder="224 224 00 55"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="4"
        >
          <b-form-group
            label="Faks"
            label-for="fax"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                TR (+90)
              </b-input-group-prepend>
              <cleave
                id="fax"
                v-model="formData.fax"
                class="form-control"
                :raw="true"
                :options="options.phone"
                placeholder="224 224 00 55"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="4"
        >
          <b-form-group
            label="E-Posta"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="formData.email"
              placeholder="E-Posta"
            />
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="6"
        >
          <b-form-group
            label="Vergi No"
            label-for="tax_no"
          >
            <b-form-input
              id="tax_no"
              v-model="formData.tax_no"
              placeholder="Vergi No"
            />
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="6"
        >
          <b-form-group
            label="Vergi Dairesi"
            label-for="tax_office"
          >
            <b-form-input
              id="tax_office"
              v-model="formData.tax_office"
              placeholder="Vergi Dairesi"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Adres"
            label-for="address"
          >
            <b-form-textarea
              id="address"
              v-model="formData.address"
              placeholder="Adres"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'SMTPConfig',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    Cleave,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
        phone: null,
        fax: null,
        email: null,
        tax_no: null,
        tax_office: null,
        address: null,
      },
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    companyData() {
      return this.$store.getters['company/getCompany']
    },
    saveStatus() {
      const status = this.$store.getters['company/getCompanySaveStatus']
      return status
    },
  },
  watch: {
    companyData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.$store.dispatch('company/companyView')
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('company/companySave', this.formData)
    },
  },
}
</script>
